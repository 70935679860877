import { Link } from "gatsby";
import React from "react";
import Layout from "../../layout/Layout";

function NotVisible() {
  return (
    <Layout>
      <div className="product-detail-wrap">
        <div className="container">
          <div className="productView-info-item align-center">
            <h4 className="form-error-message">
              {
                "looks like the page you are looking for has moved or no longer exists."
              }
            </h4>
            <Link to="/products" className="bc-cart__continue-shopping link">
              Take a look around.
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotVisible;

import React from "react";
import { Link } from "gatsby";
import ProductDetailsUnregisterRoute from "../components/products/ProductDetailsUnregisterRoute";
import NotVisible from "../sections/product/NotVisible";
import { staticPage } from "../../config/staticPage";
import Layout from "../layout/Layout";

const NotFoundPage = ({ location }) => {
  const { state } = location;

  if (state?.message?.includes("product not visible")) {
    return <NotVisible />;
  } else {
    return (
      <>
        {state?.type === "product" ? (
          <ProductDetailsUnregisterRoute
            location={location}
            product_id={state?.productId}
          />
        ) : (
          <section className="section">
            <div className="container">
              <div className="content">
                <div className="container">
                  <section className="bc-cart">
                    <div className="bc-cart__empty">
                      <h2 className="bc-cart__title--empty">Page Not Found</h2>
                      <p>
                        You've hit a page that doesn't exist. Looking to shop?
                      </p>
                      <Link to="/products" className="button">
                        Continue Shopping
                      </Link>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "404") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

NotFoundPage.Layout = Layout
export default NotFoundPage;
